import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import EmailEngagementLanding from "./email-engagement";
import KillingBusinessLanding from "./killing-business";
import MarketingPowerLanding from "./marketing-power";
import MarketingPowerLanding2 from "./marketing-power-2";
import MarketingPowerLanding4 from "./marketing-power-4";
import MarketingPowerLanding5 from "./marketing-power-5";
import EmailCampaignsLanding from "./email-campaigns";
import { getLandingPage } from "../../services/landings";
import { CHROME_STORE_EXTENSION_URL } from "../../utils/constants";
import { apiUrl } from "../../utils/config";
import styled from "styled-components";

const LANDING_ROUTES = {
  "email-engagement": EmailEngagementLanding,
  "killing-business": KillingBusinessLanding,
  "marketing-power": MarketingPowerLanding,
  "marketing-power-2": MarketingPowerLanding2,
  "marketing-power-4": MarketingPowerLanding4,
  "marketing-power-5": MarketingPowerLanding5,
  "email-campaigns": EmailCampaignsLanding,
};

const LandingRouter = () => {
  const { slug } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [landingInfo, setLandingInfo] = useState(null);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(null);

  const sendMessageToExtension = (message) => {
    const extensionId = window.reachExtensionId;

    if (!extensionId) {
      setIsExtensionInstalled(false);
      return;
    }

    try {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(extensionId, message);
    } catch (error) {
      setIsExtensionInstalled(false);
      console.log(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    if (!landingInfo) return;

    const postMessageEventHandler = (event) => {
      if (event?.data?.isExtensionInstalled !== undefined) {
        setIsExtensionInstalled(event.data.isExtensionInstalled);
      }
    };

    if (landingInfo.isForHubspot) {
      window.addEventListener("message", postMessageEventHandler);
      try {
        sendMessageToExtension(
          {
            message: "is-extension-installed",
          },
          (cb) => console.log(cb),
        );
      } catch (error) {
        console.error(error);
      }

      return () => {
        window.removeEventListener("message", postMessageEventHandler);
      };
    }
  }, [landingInfo]);

  useEffect(() => {
    const fetchLanding = async () => {
      try {
        const result = await getLandingPage(slug);
        if (!result.success) {
          setError(result.message);
        }

        setLandingInfo(result.landingPage);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLanding();
  }, [slug]);

  const handleClickInstallExtension = () => {
    window.open(CHROME_STORE_EXTENSION_URL, "_blank");
  };

  const handleClickInstallApp = async () => {
    window.location.href = `${apiUrl}/install-hubspot-app-step-2`;
  };

  const onSubscribe = () => {
    if (landingInfo && landingInfo.isForHubspot) {
      if (!isExtensionInstalled) {
        handleClickInstallExtension();
        return;
      }

      handleClickInstallApp();
      return;
    } else {
      window.open(CHROME_STORE_EXTENSION_URL, "_blank");
    }
  };

  if (loading) {
    return (
      <LoaderWrapper>
        <img src="/images/bouncing-logo.svg" alt="loader" />
      </LoaderWrapper>
    );
  }

  if (error) {
    return <Navigate to="/" replace />;
  }

  if (!landingInfo) {
    return <Navigate to="/" replace />;
  }

  const LandingComponent = LANDING_ROUTES[landingInfo.name];

  if (!LandingComponent) {
    return <Navigate to="/" replace />;
  }

  return (
    <LandingComponent
      landingInfo={landingInfo}
      onSubscribe={onSubscribe}
      isExtensionInstalled={isExtensionInstalled}
    />
  );
};

export default LandingRouter;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;
