import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-top: 56px;

  @media (max-width: 768px) {
    gap: 56px;
    align-items: flex-start;
    padding: 0 16px;
  }
`;
