import React, { useState } from "react";
import PropTypes from "prop-types";
import { Root, Placeholder, PlayButton, Video } from "./styles";

const VideoPlayer = ({ videoId, landingInfo }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <Root>
      {!isPlaying ? (
        <>
          <Placeholder>
            <img
              src={landingInfo?.videoThumbnail || "/images/engagement-placeholder.png"}
              alt="45% Email Engagement Rate"
            />
          </Placeholder>
          <PlayButton onClick={handlePlay} />
        </>
      ) : (
        <Video>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Video>
      )}
    </Root>
  );
};

VideoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  landingInfo: PropTypes.shape({
    videoThumbnail: PropTypes.string
  })
};

export default VideoPlayer;
