import React from "react";
import { Root, Content, Title, Subtitle, VideoContainer } from "./styles";
import VideoPlayer from "../VideoPlayer";
import EmailSubscribeForm from "../../EmailSubscribeForm";

const Hero = ({ title, landingInfo, onSubscribe }) => {
  return (
    <Root>
      <Content>
        <Title>{title}</Title>
        <Subtitle>
          Build and launch targeted email campaigns in minutes
          <br />
          No Uploads, No complex setup, No Marketing expertise required
        </Subtitle>

        <EmailSubscribeForm
          landingId={landingInfo?.id}
          onSuccess={onSubscribe}
          buttonText="Get Started"
          isYellow={true}
        />
      </Content>

      <VideoContainer>
        <VideoPlayer
          videoId={landingInfo?.videoId}
          landingInfo={landingInfo}
        />
      </VideoContainer>
    </Root>
  );
};

export default Hero;
