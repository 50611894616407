import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 40px;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 24px;
    align-items: flex-start;
  }
`;

export const ContentContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  max-width: 500px;
  width: 100%;
  order: ${({ $isOdd }) => ($isOdd ? 1 : 3)};

  /* added so that there is no offset in the middle of the block. */
  ${({ $isOdd }) =>
    $isOdd
      ? css`
          padding-left: 5px;
        `
      : css`
          padding-right: 5px;
        `}

  ${ContentContainer} & {
    display: none;
  }

  @media (max-width: 768px) {
    ${ContentContainer} & {
      width: 100%;
      text-align: left;
      align-items: flex-start;
      order: unset;
      padding: 0;
      display: flex;

      ${({ $isOdd }) =>
        $isOdd
          ? css`
              padding-left: 0;
            `
          : css`
              padding-right: 0;
            `}
    }

    &:not(${ContentContainer} *) {
      display: none;
    }
  }
`;

export const StepTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  border: 1px solid #b0b0b0;
  border-radius: 100px;
  width: fit-content;
`;

export const StepTagText = styled.span`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: #717784;
`;

export const StepNumberContainer = styled.div`
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  order: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    order: unset;
    height: 100%;
  }
`;

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 999px;
  background-color: ${({ $isActive }) =>
    $isActive ? "#391952" : "transparent"};
  border: 1.5px solid
    ${({ $isActive }) => ($isActive ? "transparent" : "#DFE1E6")};
  color: ${({ $isActive }) => ($isActive ? "#FFFFFF" : "#0A0A0A")};
  font-family: "Inter Tight", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.35;
`;

export const Line = styled.div`
  height: 230px;
  width: 1px;
  background-color: ${({ $isActive }) => ($isActive ? "#391952" : "#DFE1E6")};

  @media (max-width: 768px) {
    width: 1px;
    height: 100%;
    min-height: 265px;
    flex-grow: 1;
  }
`;

export const Title = styled.h3`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.03em;
  color: #0a0a0a;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: left;
  }
`;

export const ImageContainer = styled.div`
  border-radius: 24px;
  max-width: 500px;
  max-height: 294px;
  aspect-ratio: 500 / 294;
  flex: 1;
  background-color: #fff7e5;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border: 3px solid rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(80px);
  order: ${({ $isOdd }) => ($isOdd ? 3 : 1)};

  ${ContentContainer} & {
    display: none;
  }

  @media (max-width: 768px) {
    ${ContentContainer} & {
      max-width: none;
      max-height: none;
      height: auto;
      aspect-ratio: auto;
      order: unset;
      border-radius: 12px;
      border-width: 1.5px;
      display: flex;
    }

    &:not(${ContentContainer} *) {
      display: none;
    }
  }
`;

export const ImageContent = styled.div`
  border-radius: 24px;
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    border-radius: 12px;
    padding: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  border-radius: 12px;
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: fill;
`;
