import React from "react";

import {
  Container,
  Title,
  ImageContainer,
  ImageContent,
  ImageWrapper,
  Image,
  StepNumber,
  StepNumberContainer,
  StepTag,
  StepTagText,
  TextContainer,
  Line,
  ContentContainer,
} from "./styles";

const StepItem = ({ step }) => {
  const { id, title, imageSrc } = step;
  const isOdd = id % 2 !== 0;
  const isActive = id === 1;

  const renderStepContent = () => (
    <>
      <StepTag>
        <StepTagText>{`STEP ${id}`}</StepTagText>
      </StepTag>
      <Title>{title}</Title>
    </>
  );

  const renderStepImage = () => (
    <ImageContent>
      <ImageWrapper>
        {imageSrc && <Image src={imageSrc} alt={title} />}
      </ImageWrapper>
    </ImageContent>
  );

  return (
    <Container>
      {/* Desktop Layout */}
      <TextContainer $isOdd={isOdd}>{renderStepContent()}</TextContainer>

      <StepNumberContainer>
        <StepNumber $isActive={isActive}>{id}</StepNumber>
        <Line $isActive={isActive} />
      </StepNumberContainer>

      <ImageContainer $isOdd={isOdd}>{renderStepImage()}</ImageContainer>

      {/* Mobile Layout */}
      <ContentContainer>
        <TextContainer $isOdd={isOdd}>{renderStepContent()}</TextContainer>

        <ImageContainer $isOdd={isOdd}>{renderStepImage()}</ImageContainer>
      </ContentContainer>
    </Container>
  );
};

export default StepItem;
