import React from "react";

import Steps from "./Steps";

import { Container, Title } from "./styles";

function Description() {
  return (
    <Container>
      <Title>
        Boost your Hubspot capabilities <br /> to send personalized emails at
        scale
      </Title>
      <Steps />
    </Container>
  );
}

export default Description;
